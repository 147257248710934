import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const DealsFront = props => {
  const { news, title } = props
  const [modalActive, setModalActive] = useState('not-active')

  const toggleModal = e => {
    e.preventDefault()
    console.log(modalActive)
    modalActive === 'is-active'
      ? setModalActive('not-active')
      : setModalActive('is-active')
  }

  return (
    <section className="section section-news ">
      <div className="container">
        <div className="content">
          <h1 className="title has-text-white has-text-centered">{title}</h1>
          <div className="columns is-centered is-multiline">
            {news
              ? news.map(({ node: newsItem }) => (
                  <>
                    <div className="column is-10 is-flex" key={newsItem.id}>
                      <div className="tile-inner withholding-pattern is-flex">
                        <h2 className="tile-title has-text-centered">
                          <span className="light">-</span>{' '}
                          {parse(newsItem.title)}{' '}
                          <span className="light">-</span>
                        </h2>
                        <div
                          className={
                            newsItem.extra.banner ? 'columns' : 'no-columns'
                          }
                        >
                          <div
                            className={`${
                              newsItem.extra.banner
                                ? 'column is-8'
                                : 'no-column'
                            } tile-content`}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: newsItem.content,
                              }}
                            />
                            <div className="tile-cta has-text-centered">
                              <div>
                                <a
                                  className="button has-text-white is-medium is-danger mt-4"
                                  href={newsItem.extra.urlActie}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {newsItem.extra.ctaButtonText
                                    ? newsItem.extra.ctaButtonText
                                    : 'Profiteer nu van de korting'}
                                </a>
                              </div>
                              {newsItem.extra.qrCode ? (
                                <div>
                                  <a
                                    onClick={e => toggleModal(e)}
                                    className="button"
                                  >
                                    Toon QRCode
                                  </a>
                                </div>
                              ) : null}
                            </div>

                            {newsItem.extra.qrCode ? (
                              <div className={`modal ${modalActive}`}>
                                <div className="modal-background is-flex ">
                                  <div className="modal-content is-flex is-justify-content-center is-align-items-center">
                                    <div
                                      className="has-text-centered"
                                      dangerouslySetInnerHTML={{
                                        __html: newsItem.extra.qrCode,
                                      }}
                                    />
                                    <button
                                      className="modal-close is-large"
                                      aria-label="close"
                                      onClick={e => toggleModal(e)}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div
                            className={`${
                              newsItem.extra.banner
                                ? 'column is-4'
                                : 'no-column'
                            } tile-banner has-text-centered`}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: newsItem.extra.banner,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
          </div>
        </div>
        <nav className="has-text-centered">
          <Link className="has-text-white is-size-3" to="/nieuws/">
            Alle nieuws
            <FontAwesomeIcon
              style={{
                color: '#fff',
                'padding-left': '12px',
              }}
              icon={faArrowRight}
            />
          </Link>
        </nav>
      </div>
    </section>
  )
}

export default DealsFront

DealsFront.propTypes = {
  news: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  data: PropTypes.shape({
    allWordpressCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
