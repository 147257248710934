import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import SocialSharing from '../components/SocialSharing/SocialSharing'
import Pagination from '../components/Pagination'
import LinkCloud from '../components/LinkCloud/LinkCloud'
import Plopsa from '../components/Affiliates/Plopsa'
import Disney from '../components/Affiliates/Disney'
import PlopsaHotel from '../components/Affiliates/PlopsaHotel'
import PlopsaStation from '../components/Affiliates/PlopsaStation'
import DealsFront from '../components/DealsFront'
import Helmet from 'react-helmet'

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWpPost
    const { edges: categories } = data.allWpCategory
    const { edges: nieuwsberichten } = data.allWpNieuwsbericht
    const { siteUrl, title: siteTitle } = data.site.siteMetadata
    const {
      metaTitle,
      metaDescription,
      ogTitle,
      ogDescription,
    } = data.wpHomepage.seo
    const home = true

    return (
      <Layout path="/">
        <Helmet>
          <title>{`${metaTitle} | ${siteTitle}`}</title>
          <link rel="canonical" href={siteUrl} />
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={`${ogTitle} | ${siteTitle}`} />
          <meta name="og:description" content={ogDescription} />
          <meta name="og:url" content={siteUrl} />
        </Helmet>
        <DealsFront news={nieuwsberichten} title="Laatste nieuwe deals" />
        <section className="section-post">
          <div className="container">
            <Disney />
            <PostList
              posts={posts}
              categories={categories}
              home={home}
              title="Alle pretparken"
            />
            <Pagination pageContext={pageContext} pathPrefix="/" />
            <Plopsa />
            <PlopsaStation />
            <PlopsaHotel />
            <SocialSharing siteUrl={siteUrl} />
            <LinkCloud />
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allWpPost {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWpCategory(filter: { wpParent: { node: { name: { eq: "Land" } } } }) {
      edges {
        node {
          name
          slug
          id
          count
        }
      }
    }
    allWpNieuwsbericht(
      filter: { extra: { upfront: { eq: true } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...NewsListFields
        }
      }
    }
    wpHomepage(id: { eq: "cG9zdDoxMDMy" }) {
      seo {
        metaTitle
        metaDescription
        ogTitle
        ogDescription
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
