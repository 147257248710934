import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <div className="navbar-item">
            <Link
              className="button has-text-white is-success"
              to={previousPagePath}
              rel="prev"
            >
              Vorige
            </Link>
          </div>
        )}
        {nextPagePath && (
          <div className="navbar-item">
            <Link
              className="button has-text-white is-success"
              to={nextPagePath}
              rel="next"
            >
              Volgende
            </Link>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Pagination
